(function ($) {
  var active_font_size = 'font-size-normal';
  var selector_change_font_size = ".btn-change-font-size";

  function get_menu_selected_font_size(checked_element) {
    return $(checked_element).data('font-size-modifier');
  }

  function set_menu_selected_font_size(font_size) {
    $(selector_change_font_size).prop('checked', false);
    $.each($(selector_change_font_size + '[data-font-size-modifier="' + font_size + '"]'), function (i, radio) {
      $(radio).prop('checked', true);
    });
  }

  function update_font_size_in_html() {
    $("html").removeClass(Object.keys(vtx_accessibility.font_size.font_size_names).join(' ')).addClass(active_font_size);
  }

  function save_user_font_size() {
    localStorage.setItem('active_font_size', active_font_size);
  }

  function load_saved_user_font_size() {
    active_font_size = null !== localStorage.getItem('active_font_size') ? localStorage.getItem('active_font_size') : active_font_size;
  }

  function init_global_font_size() {
    set_menu_selected_font_size(active_font_size);
    $(selector_change_font_size).on("change", function (e) {
      active_font_size = get_menu_selected_font_size(e.currentTarget);
      save_user_font_size();
      update_font_size_in_html();
    });
  } // Early call to restore the state from the LocalStorage


  load_saved_user_font_size();
  update_font_size_in_html();
  window.addEventListener('DOMContentLoaded', function () {
    init_global_font_size();
  });
})(jQuery); // end conflict sage jQuery