(function ($) {
  var active_stylesheet_name = 'sage-css';
  var selector_toggle_high_contrast = ".toggle-accessibility-stylesheet";

  function get_menu_selected_stylesheet(checked_element) {
    if ($(checked_element).prop('checked')) {
      return 'high_contrast';
    }

    return 'sage-css';
  }

  function set_menu_selected_stylesheet(stylesheet) {
    if ('high_contrast' === stylesheet) {
      $.each($(selector_toggle_high_contrast), function (i, toggle) {
        $(toggle).prop('checked', true);
      });
    }
  }
  /**
   * Utilise la variable global «accessibility_stylesheet_toggle_path» qui est set avec «wp_localize_script»
   */


  function update_stylesheet_href() {
    var link;

    if ('high_contrast' === active_stylesheet_name) {
      var head = document.getElementsByTagName('HEAD')[0];
      link = document.createElement('link');
      link.id = 'sage-accessibility-css-css';
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = vtx_accessibility.stylesheet.stylesheet_paths[active_stylesheet_name];
      head.appendChild(link);
    } else {
      link = document.getElementById('sage-accessibility-css-css');
      link.parentNode.removeChild(link);
    }
  }

  function save_user_stylesheet() {
    localStorage.setItem('active_stylesheet_name', active_stylesheet_name);
  }

  function load_saved_user_stylesheet() {
    active_stylesheet_name = null !== localStorage.getItem('active_stylesheet_name') ? localStorage.getItem('active_stylesheet_name') : active_stylesheet_name;
  }

  function init_toggle_high_contrast() {
    set_menu_selected_stylesheet(active_stylesheet_name);
    $(selector_toggle_high_contrast).on("change", function (e) {
      active_stylesheet_name = get_menu_selected_stylesheet(e.currentTarget);
      save_user_stylesheet();
      update_stylesheet_href();
    });
  } // Early call to restore the state from the LocalStorage


  load_saved_user_stylesheet();
  update_stylesheet_href();
  window.addEventListener('DOMContentLoaded', function () {
    init_toggle_high_contrast();
  });
})(jQuery); // end conflict sage jQuery